import React from 'react'
import { graphql } from 'gatsby'
import SizeGuidePage from 'src/views/sizeguide'
import GatsbySeoCustom from 'src/components/GatsbySeoCustom'

function Page() {
  return (
    <>
      <GatsbySeoCustom
        title="Size Guide"
        description="Size Guide Component"
        language="pt-BR"
        noindex
        nofollow
      />
      <SizeGuidePage />
    </>
  )
}

export const query = graphql`
  query SizeGuidePageQuery {
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

export default Page
